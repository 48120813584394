
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/dispatch'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import videojs from 'video.js'

require('video.js/dist/video-js.css')
@Component({
  name: 'manholeManage',
  filters: {
    dispatchState: (value: string) => {
      switch (value) {
        case '1':
          return '待核实'
        case '2':
          return '处置中'
        case '3':
          return '已完成'
        case '4':
          return '核实不通过'
        case '5':
          return '已取消'
        default: return ''
      }
    }
  }
})
export default class extends Vue {
  private searchInfo = {
    typeId: '',
    projectId: '',
    dispatchGrade: '',
    dispatchState: ''
  }

  private infoData = []
  private size = 10
  private page = 1
  private total = 0
  private itemData = {
    dispatchHandleList: []
  }

  private itemIndex: null | number = null

  private typeList = []
  private gradeList = []
  private dateRange: Array<string> = []
  private projectList = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private drawerData: any | null = null

  private videoHtml = ''
  private videoPlay: any = null
  private videoMarkers: Array<AMap.Marker> = []
  private guideboardMarkers: Array<AMap.Marker> = []
  private videoSelect = true
  private guideboard = true
  private switchDisabled = false
  private btnDisabled = false
  private activeName = '1'
  private scenariosDetail = {}

  private onlineImg = require('@/assets/icon/dispatch/icon_map_shijian.svg')
  private onlineImgCursor = require('@/assets/icon/dispatch/icon_map_shijian_dianji.svg')
  private videoOnlineImg = require('@/assets/icon/monitor/online.svg')
  private videoOnlineImgCursor = require('@/assets/icon/monitor/online2.svg')
  private offImg = require('@/assets/icon/monitor/off.svg')
  private offImgCursor = require('@/assets/icon/monitor/off2.svg')
  private guideboardOnlineImg = require('@/assets/icon/guideboard/icon_map_zhihuilupai_zaixian.svg')
  private guideboardOnlineImgCursor = require('@/assets/icon/guideboard/icon_map_zhihuilupai_zaixian_dianji.svg')
  private guideboardOffImg = require('@/assets/icon/guideboard/icon_map_zhihuilupai_lixian.svg')
  private guideboardOffImgCursor = require('@/assets/icon/guideboard/icon_map_zhihuilupai_lixian_dianji.svg')

  created () {
    this.initTime()
    this.getProjectList()
    this.getTypeList()
    this.getGradeList()
  }

  destroyed (): void {
    if (this.markers.length) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.videoMarkers.length) {
      this.videoMarkers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerVideoOpen)
      })
    }
    if (this.guideboardMarkers.length) {
      this.guideboardMarkers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.videoPlay) {
      this.videoPlay.dispose()
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  initTime () {
    this.dateRange = [this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectProjectByList).then((res) => {
      this.projectList = res.list || []
      if (res.list && res.list[0]) {
        this.searchInfo.projectId = res.list[0].projectId
        this.getProjectLocation()
      }
    })
  }

  getData () {
    this.drawerClose()
    this.map && this.map.remove(this.markers)
    this.markers = []
    const info = this.dateRange ? {
      startTime: this.dateRange[0],
      endTime: this.dateRange[1],
      ...this.searchInfo
    } : {
      ...this.searchInfo
    }
    this.$axios.get(this.$apis.dispatch.selectDispatchByPage, {
      notFinished: '1',
      ...info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.infoData = res.list || []
      this.total = res.total || 0
      this.drawMarker(this.infoData, '')
    })
  }

  getVideo () {
    this.drawerClose()
    this.map && this.map.remove(this.videoMarkers)
    this.videoMarkers = []
    this.$axios.get(this.$apis.monitor.cameraList, {
      projectId: this.searchInfo.projectId,
      deviceTypeId: '1002'
    }).then(res => {
      this.drawMarker(res.devices || [], '1002')
    })
  }

  // 左侧类型名称长度
  getTypeName (item: any) {
    const str = item.dispatchState === '1' ? 10 : 5
    return item.typeName && item.typeName.length > str ? item.typeName.substring(0, str) + '...' : item.typeName
  }

  // 路牌
  getGuideboard () {
    this.drawerClose()
    this.map && this.map.remove(this.guideboardMarkers)
    this.guideboardMarkers = []
    this.$axios.get(this.$apis.dispatch.selectGuideboard, {
      projectId: this.searchInfo.projectId
    }).then(res => {
      this.drawMarker(res.devices || [], '1029')
    })
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.searchInfo.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 事件类型
  getTypeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 事件等级
  getGradeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'dispatchGrade'
    }).then(res => {
      this.gradeList = res.dispatchGrade || []
    })
  }

  getItemData (dispatchId: string) {
    this.$axios.get(this.$apis.dispatch.selectDispatchByDispatchId, {
      dispatchId
    }).then(res => {
      this.itemData = res || {}
    })
  }

  // 预案匹配
  getScenariosDetail (scenariosId: string) {
    this.$axios.get(this.$apis.scenarios.selectDispatchScenariosByScenariosId, {
      scenariosId: scenariosId
    }).then(res => {
      this.scenariosDetail = res || {}
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
    if (this.map) {
      this.map.setCenter(center)
      this.map.clearMap()
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)
      this.getData()
      this.getVideo()
      this.getGuideboard()
    } else {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        const map = new AMap.Map('map', {
          center: center,
          zoom: 11
        })
        this.map = map
        this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)
        this.getData()
        this.getVideo()
        this.getGuideboard()
        // 点击地图关闭抽屉
        this.map.on('click', this.drawerClose)
      })
    }
  }

  // 地图打点
  drawMarker (list: Array<TableData>, type: string) {
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: any, index: number) => {
        if (item.longitude && item.latitude) {
          const icon = type === '1002' ? this.getVideoIcon(false, item.deviceStatus) : type === '1029' ? this.getGuideboardIcon(false, item.deviceStatus) : this.getIcon(false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = JSON.parse(JSON.stringify({ ...item, index: index, type }))

          if (type === '1002') {
            marker.on('click', this.drawerVideoOpen)
            this.map && this.videoSelect && this.map.add(marker)
            this.videoMarkers.push(marker)
          } else if (type === '1029') {
            marker.on('click', this.drawerOpen)
            this.map && this.guideboard && this.map.add(marker)
            this.guideboardMarkers.push(marker)
          } else {
            marker.on('click', this.drawerOpen)
            this.map && this.map.add(marker)
            this.markers.push(marker)
          }
        }
      })
    }
  }

  // 抽屉打开, 如果点地图上的点，则e.target.detail都有，如果点左侧列表：左侧列表对应地图上有点，则e等同于地图上打点的e.target，如果左侧列表对应地图没有点，则e对应的是e.target.detail
  drawerOpen (e: any) {
    const ev = e.target ? e.target : e
    const data = ev.detail || ev
    if (this.drawerData === null || (this.drawerData && this.drawerData.dispatchId !== data.dispatchId) || (this.drawerData && this.drawerData.deviceId !== data.deviceId)) {
      if (this.drawerData && (this.drawerData.dispatchId !== data.dispatchId || this.drawerData.deviceId !== data.deviceId)) {
        this.drawerClose()
      }
      this.drawerData = data
      // 如果地图上有打点，则切换打点图标
      if (e.target || e.detail) {
        // 点击切换大图标
        const iconClick = data.type === '1029' ? this.getGuideboardIcon(true, data.deviceStatus) : this.getIcon(true)
        ev.setOffset(new AMap.Pixel(-22, -64))
        ev.setIcon(iconClick)
      }
    }
    this.drawer = true
    if (!data.type) {
      this.itemIndex = data.index
      this.getItemData(data.dispatchId)
      if (data.scenariosId) {
        this.getScenariosDetail(data.scenariosId)
      } else {
        this.scenariosDetail = {}
      }
    }
  }

  // 视频打开
  drawerVideoOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId)) {
      if (this.drawer) {
        this.drawerClose()
      }
      this.drawerData = e.target.detail
      // 点击切换大图标·
      const iconClick = this.getVideoIcon(true, e.target.detail.deviceStatus)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
      this.drawer = true
      if (this.drawerData.deviceModel === 'HIK_ISC') {
        this.$axios.get(this.$apis.monitor.selectDeviceIscUrl, {
          deviceNumber: this.drawerData.deviceNumber
        }).then(res => {
          this.playVideo(res || '')
        })
      } else {
        // 渲染video标签
        this.playVideo(this.drawerData.addressLive || '')
      }
    }
  }

  // 视频播放
  playVideo (addressLive: string) {
    // 渲染video标签
    this.videoHtml = `<video
          id="videoPlay"
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered"
          controls
          autoplay
          muted
          preload="auto"
          style="width: 100%;height: 100%;"
        >
          <source src="${addressLive}" type="application/x-mpegURL" />
        </video>`
    this.$nextTick(() => {
      const options: any = {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        controlBar: true
      }
      this.videoPlay = videojs('videoPlay', options)
      this.videoPlay.play()
    })
  }

  // 预警状态开关
  switchChange () {
    this.switchDisabled = true
    if (this.drawerData) {
      const datas = JSON.parse(JSON.stringify(this.drawerData))
      this.$axios.post(this.$apis.dispatch.insertGuideboardSwitch, {
        deviceId: datas.deviceId,
        deviceNumber: datas.deviceNumber,
        projectId: this.searchInfo.projectId,
        switchStatus: datas.runStatus
      }).then(() => {
        const txt = this.drawerData.runStatus === '0' ? '开启' : '关闭'
        this.$message.success(txt + '成功。')
        this.markers.map((item: any) => {
          if (datas && (item.detail.deviceId === datas.deviceId)) {
            item.detail.runStatus = this.drawerData.runStatus
          }
        })
      }).catch(() => {
        this.drawerData.runStatus = this.drawerData.runStatus === '0' ? '1' : '0'
      }).finally(() => {
        this.switchDisabled = false
      })
    }
  }

  // 解除sos
  removeSOS () {
    this.btnDisabled = true
    if (this.drawerData) {
      const datas = JSON.parse(JSON.stringify(this.drawerData))
      this.$axios.post(this.$apis.dispatch.insertGuideboardSwitch, {
        deviceId: datas.deviceId,
        deviceNumber: datas.deviceNumber,
        projectId: this.searchInfo.projectId,
        switchStatus: '2'
      }).then(() => {
        this.$message.success('解除SOS成功。')
        this.markers.map((item: any) => {
          if (datas && (item.detail.deviceId === datas.deviceId)) {
            item.detail.sosStatus = '0'
          }
        })
      }).catch(() => {
        this.drawerData.sosStatus = '1'
      }).finally(() => {
        this.btnDisabled = false
      })
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const id = this.drawerData.dispatchId ? this.drawerData.dispatchId : this.drawerData.deviceId
        const markers = this.map.getAllOverlays('marker')
        const index = markers.findIndex((item: any) => {
          if (item.detail.dispatchId) {
            return item.detail.dispatchId === id
          } else {
            return item.detail.deviceId === id
          }
        })
        // 如果地图上有打点，则更改图标状态
        if (index >= 0) {
          const marker: any = markers[index] as AMap.Marker
          const icon = marker.detail.type === '1002' ? this.getVideoIcon(false, marker.detail.deviceStatus) : marker.detail.type === '1029' ? this.getGuideboardIcon(false, marker.detail.deviceStatus) : this.getIcon(false)
          marker.setOffset(new AMap.Pixel(-18, -48))
          marker.setIcon(icon)
        }
        // 如果是视频，则停止视频播放
        if (this.drawerData.type === '1002') {
          this.videoPlay.dispose()
          this.videoHtml = ''
        }
      }
      // 清空抽屉
      this.drawerData = null
      this.itemIndex = null
    }
  }

  // 获取icon
  getIcon (isCurrent: boolean) {
    return !isCurrent ? this.onlineImg : this.onlineImgCursor
  }

  // 获取监控icon
  getVideoIcon (isCurrent: boolean, state: string) {
    if (state === '1') {
      return isCurrent ? this.videoOnlineImgCursor : this.videoOnlineImg
    } else {
      return isCurrent ? this.offImgCursor : this.offImg
    }
  }

  // 获路牌icon
  getGuideboardIcon (isCurrent: boolean, state: string) {
    if (state === '1') {
      return isCurrent ? this.guideboardOnlineImgCursor : this.guideboardOnlineImg
    } else {
      return isCurrent ? this.guideboardOffImgCursor : this.guideboardOffImg
    }
  }

  // 列表点击
  itemSwitch (info: any, index: number) {
    this.itemIndex = index
    if (this.map) {
      const markers = this.map.getAllOverlays('marker')
      const markerIndex = markers.findIndex((item: any) => item.detail.dispatchId === info.dispatchId)
      this.drawerOpen(markerIndex >= 0 ? markers[markerIndex] : { ...info, index: index })
    }
  }

  // 视频筛选点击
  videoSelectClick (value: boolean) {
    if (value) {
      this.map && this.map.add(this.videoMarkers)
    } else {
      this.map && this.map.remove(this.videoMarkers)
    }
  }

  // 路牌筛选点击
  guideboardClick (value: boolean) {
    if (value) {
      this.map && this.map.add(this.guideboardMarkers)
    } else {
      this.map && this.map.remove(this.guideboardMarkers)
    }
  }

  colorState (value: string) {
    switch (value) {
      case '1':
        return 'rgba(245, 34, 45, 1)'
      case '2':
        return 'rgba(245, 84, 34, 1)'
      case '3':
        return 'rgba(255, 136, 23, 1)'
      case '4':
        return 'rgba(255, 193, 23, 1)'
      default :
        return ''
    }
  }

  searchData () {
    this.page = 1
    this.drawerClose()
    this.getProjectLocation()
  }
}
